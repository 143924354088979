.snippet-advertiser{
	text-align: center;

	&__image{
		display: inline-block;
		position: relative;
		width: 76px;
		height: 76px;
		border-radius: 100%;
		overflow: hidden;

		img{
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			@include object-fit();
		}
	}

	&__caption{
		&-title{
			color: $color--orange;
			font-size: 18px;
			text-transform: uppercase;
			margin-bottom: .5rem;
		}

		&-infos{
			@include flex-wrap(wrap);
			@include flex-justify-content(center);
			margin-bottom: 1.5rem;

			> li{
				font-weight: 500;

				&:not(:last-child){
					margin-right: 20px;
				}

				> span{
					display: inline-block;

					&:before{
						content: '';
						display: inline-block;
						margin-right: 7px;
						width: 12px;
						height: 12px;
					}

					&.phone-info{
						&:before{
							content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="11.94" height="11.965" viewBox="0 0 11.94 11.965"><path d="M12.166,8.781,10.5,7.111a1.111,1.111,0,0,0-1.849.417,1.136,1.136,0,0,1-1.312.716,5.149,5.149,0,0,1-3.1-3.1A1.081,1.081,0,0,1,4.95,3.831a1.111,1.111,0,0,0,.417-1.849L3.7.313a1.191,1.191,0,0,0-1.61,0L.955,1.446C-.178,2.639,1.074,5.8,3.877,8.6s5.963,4.115,7.156,2.922l1.133-1.133A1.191,1.191,0,0,0,12.166,8.781Z" transform="translate(-0.539 0)"/></svg>');
						}
					}

					&.datetime-info{
						&:before{
							content: url('data:image/svg+xml; utf8, <svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"> <g> <g> <circle cx="0.5" cy="0.5" r="0.5" transform="translate(8.333 4.333)"/> <path d="M10.125.937H9.516V.469a.469.469,0,1,0-.937,0V.937H6.445V.469a.469.469,0,1,0-.937,0V.937H3.4V.469a.469.469,0,0,0-.937,0V.937H1.875A1.877,1.877,0,0,0,0,2.812v7.312A1.877,1.877,0,0,0,1.875,12H5.461a.469.469,0,1,0,0-.937H1.875a.939.939,0,0,1-.937-.937V2.812a.939.939,0,0,1,.937-.937h.586v.469a.469.469,0,0,0,.937,0V1.875H5.508v.469a.469.469,0,1,0,.937,0V1.875H8.578v.469a.469.469,0,1,0,.937,0V1.875h.609a.939.939,0,0,1,.937.937V5.484a.469.469,0,1,0,.937,0V2.812A1.877,1.877,0,0,0,10.125.937Z"/> <path d="M272.836,270a2.836,2.836,0,1,0,2.836,2.836A2.839,2.839,0,0,0,272.836,270Zm0,4.734a1.9,1.9,0,1,1,1.9-1.9A1.9,1.9,0,0,1,272.836,274.734Z" transform="translate(-263.672 -263.672)"/> <path d="M372.148,330.961h-.211v-.492a.469.469,0,0,0-.937,0v.961a.469.469,0,0,0,.469.469h.68a.469.469,0,0,0,0-.937Z" transform="translate(-362.305 -322.266)"/> <circle cx="0.5" cy="0.5" r="0.5" transform="translate(6.333 4.333)"/> <circle cx="0.5" cy="0.5" r="0.5" transform="translate(4.333 6.333)"/> <circle cx="0.5" cy="0.5" r="0.5" transform="translate(2.333 4.333)"/> <circle cx="0.5" cy="0.5" r="0.5" transform="translate(2.333 6.333)"/> <circle cx="0.5" cy="0.5" r="0.5" transform="translate(2.333 8.333)"/> <circle cx="0.5" cy="0.5" r="0.5" transform="translate(4.333 8.333)"/><circle cx="0.5" cy="0.5" r="0.5" transform="translate(4.333 4.333)"/></g></g></svg>');
						}
					}
				}
			}
		}
	}
}