.gird-layout .quick-item {
    width: 32.3333334%;
    margin: 18px 2px;
    float: left;
    height: auto;
    padding: 0;
    max-height: none !important
}

.gird-layout .item-image-box {
    overflow: hidden
}

.gird-layout.my-profile .ad-info .item-price {
    font-size: 14px
}

.quick-item {
    border: 1px solid #f3f3f3;
    margin: 18px 0;
    min-height: 153px;
    border-radius: 2px;
    max-height: 153px
}

.quick-item.highlight {
    border: 1px solid #f3f3f3 !important;
}

.quick-item:hover .item-image img {
    -moz-transform: scale(1.05) rotate(2deg);
    -webkit-transform: scale(1.05) rotate(2deg);
    -o-transform: scale(1.05) rotate(2deg);
    -ms-transform: scale(1.05) rotate(2deg);
    transform: scale(1.05) rotate(2deg)
}

.item-image-box {
    max-height: 200px;
    overflow: hidden
}

.item-image-box, .item-info {
    padding: 0;
    margin: 0;
    position: relative
}

.item-info {
    min-height: 152px
}

.item-image {
    position: relative
}

.item-image img {
    width: 100%;
    min-height: 200px;
    @include transition(.5s);
}

.gird-layout .breadcrumb > li {
    width: 100%;
    display: inline-block;
    overflow: hidden
}

.item-badges {
    position: absolute;
    top: 0;
    z-index: 999;
    right: 0;
    width: 100%;
    display: block;
    font-size: 13px;
    padding: 0;
    overflow: hidden;
    height: 100px
}

.item-badges .featured {
    background-color: #43a047 !important;
    float: left;
    transform: rotate(-45deg);
    left: -67px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 12.5px;
    margin: 0
}



.item-badges span {
    color: #fff;
    display: inline-block;
    padding: 1px 10px;
    float: right;
    background-color: #F1B500;
    border-radius: 3px;
    top: 15px;
    right: 15px;
    position: absolute
}

.quick-item .ad-info {
    overflow: hidden
}


.quick-item .ad-listing {
    position: relative;
    overflow: hidden;
    height: 152px;
    width: 100%;
    padding-left: 260px
}

.quick-item .ad-listing .image {
    width: 250px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 9;
    -moz-transition: .6s ease;
    -webkit-transition: .6s ease;
    transition: .6s ease;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-transform: scale(1.01, 1.01);
    -ms-transform: scale(1.01, 1.01);
    -webkit-transform: scale(1.01, 1.01);
    transform: scale(1.01, 1.01)
}

.quick-item .ad-listing figure {
    text-shadow: 0 1px 1px rgba(0, 0, 0, .5);
    position: absolute;
    text-align: left;
    width: 100%;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    z-index: 1
}

.ad-info {
    padding: 16px;
}

.ad-info .contact-options {
    margin: -25px;
    padding: 0 10px;
}

.ad-info .contact-options li a {
    display: block;
    text-decoration: none;
    margin-right: 2px;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    color: #b7b7b7;
    font-size: 12px;
    position: relative;
    background: #f4f4f4;
}

.ad-info .contact-options li a.active,
.ad-info .contact-options li a.fa-heart:hover {
    background: $color--orange;
    color: $color--white;
}

.ad-info .view-btn {
    text-align: center;
    position: absolute;
    bottom: 20px;
    right: 4px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    padding: 9px 28px;
    text-decoration: none;
    border: 2px solid #e0e0e0;
    color: inherit;
}

.ad-info .item-title{
	font-weight: 300;
}

.ad-info .breadcrumb {
	padding: 8px 15px 8px 0;
    margin-bottom: 0;
    color: #909090 !important;
    font-size: 13px;

    > li {
    	display: inline-block;

    	+ li:before {
		    content: "\f101";
		    font-family: FontAwesome;
		    padding: 0 5px;
		    color: #909090;
		}

		a{
			&:hover{
				color: $color--orange;
			}
		}
	}
}

.ad-info .item-details {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.ad-info .item-details li {
    display: inline-block;
    margin-right: 15px;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
}

.ad-info .item-details li i {
    margin-right: 5px;
    color: $color--orange;
}