/* Social Media */
.social-media{
	@include flex-align-items(center);
	@include flex-wrap(wrap);

	> li{
		&:not(:last-child){
			margin-right: 25px;
		}

		a{
			@include transition(.4s);
			color: $color--gray-dark;

			&:hover{
				color: $color--orange;
			}
		}
	}
}
