.snippet-ad{
	@include transition(.4s);
	box-shadow: 0 3px 6px rgba($color--black, .06);
	border-radius: 4px;
	overflow: hidden;

	a:hover &{
		box-shadow: 0 3px 6px rgba($color--black, .1);
	}

	&__image{
		position: relative;
		width: 100%;
		padding-bottom: 55%;
		margin: 0;
		overflow: hidden;

		img{
			@include object-fit();
			@include transition(.4s);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			a:hover &{
				@include scale(1.02);
			}
		}

		.btn-play{
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			width: 30px;
			height: 30px;
			margin-top: -15px;
			margin-left: -15px;
			background: {
				image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath d='M15,24.75l5.506-4.129L24,18l-9-6.75ZM18,3A15,15,0,1,0,33,18,15.005,15.005,0,0,0,18,3Zm0,27A12,12,0,1,1,30,18,12.016,12.016,0,0,1,18,30Z' transform='translate(-3 -3)' fill='#{$color--white}'/%3E%3C/svg%3E%0A");
				repeat: no-repeat;
				position: center;
				size: contain;
			}
		}
	}

	&__caption{
		min-height: 105px;
		padding: 15px;
		font-weight: 300;
		font-size: 12px;

		&-title{
			font-size: 14px;
		}
	}
}