.ad-page{
	&-wrap{
		padding-top: 30px;
		padding-bottom: 90px;

		@include max-screen(bp("mobile")) {
			padding-top: 0;
			padding-bottom: 40px;
		}

		&__video{
			margin-bottom: 60px;

			@include max-screen(bp("mobile")) {
				margin-bottom: 25px;
			}
		}

		&__content{
			margin-bottom: 140px;

			@include max-screen(bp("phablet")) {
				margin-bottom: 60px;
			}

			&-divider{
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 50%;
				border-right: 1px solid $color--gray-line;
			}
		}

		&__map{
			margin-bottom: 150px;

			// .map{
			// 	outline: 0;
			// 	position: relative;
			// 	width: 100%;
			// 	height: 436px;
			// 	background-color: $color--white;
			// 	padding: 15px;
			// 	border-radius: 4px;
			// }
		}
	}

	&-content{
		// padding-top: 54px;

		&__title{
			font-size: 28px;
			color: $color--orange;
			margin-bottom: 1.3rem;

			@include max-screen(bp("mobile")) {
				font-size: 16px;
			}
		}

		&__meta{
			@include flex-wrap(wrap);
			color: $color--gray-medium;
			margin-bottom: 1.3rem;

			li{
				position: relative;				

				@include max-screen(bp("mobile")) {
					font-size: 10px;
				}

				&:not(:last-child){
					margin-right: 10px;
					padding-right: 11px;

					@include max-screen(bp("mobile")) {
						margin-right: 5px;
						padding-right: 6px;
					}

					&:after{
						@include pseudo();
						width: 1px;
						height: 8px;
						margin-top: -4px;
						top: 50%;
						right: 0;
						background-color: rgba($color--gray-medium, .4);
					}
				}
			}
		}

		&__caption{
			margin-bottom: 2rem;
		}

		&__actions{
			@include flex-wrap(wrap);
			margin: 0;

			> li{
				margin-right: 24px;

				> a{
					@include transition(.4s);
					color: $color--gray-medium;

					&.active,
					&:hover{
						color: $color--orange;
					}

					.icon{
						margin-right: 8px;
					}
				} 
			}
		}
	}
}