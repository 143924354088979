.map{
	outline: 0;
	position: relative;
	width: 100%;
	height: 250px;
	background-color: $color--white;
	padding: 15px;
	border-radius: 4px;

	&.map-ad{
		height: 436px;
	}
}