.btn-small-rounded{
	display: inline-block;
	padding: .38rem 1.45rem;
	border: 1px solid $color--gray-dark;
	color: $color--gray-dark;
	font-weight: 300;
	font-size: 14px;
	border-radius: 50rem;
	@include transition(.4s);

	@include max-screen(bp("mobile")) {
		padding: .38rem 1.4rem;
		font-size: 13px;
	}

	&:hover{
		background-color: $color--gray-dark;;
		color: $color--white;
	}
}

.btn-bars{
	@include reset-button();
	color: $color--orange;
}

.btn-upload{
	cursor: pointer;
	min-width: 115px;
	text-align: center;
	padding: 11px 12px;
    margin: 0;
    white-space: nowrap;
    color: #6f6f6f;

    .fa{
    	font-size: 120%;
    }
}

.btn-contact-affix{
	@include max-screen(bp("mobile")) {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 99;
	}
}