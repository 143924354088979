// Home
.section-search{
	@include background-cover();
	background-position: top center;
	min-height: 490px; 
	padding: 30px 0;

	@include max-screen(bp("mobile")) {
		min-height: 434px;
	}

	.section-title{
		font-size: 44px;
		font-weight: 300;
		line-height: 1.48;
		// margin-bottom: 1.3rem;

		@include max-screen(bp("tablet")) {
			font-size: 36px;
		}

		@include max-screen(bp("mobile")) {
			font-size: 24px;
		}

		strong{
			font-weight: 600;
		}
	}
}

.section-advantages{
	padding: 60px 0;

	@include max-screen(bp("mobile")) {
		padding: 40px 0 25px;

	}

	.section{
		&-image{
			img{
				width: 100%;
				border-radius: 4px;
				box-shadow: 0 3px 6px rgba($color--black, .06);
			}
		}

		&-title{
			font-size: 15px;
			color: $color--orange;

			@include max-screen(bp("mobile")) {
				font-size: 13px;
			}
		}

		&-subtitle{
			margin-top: 0;
			font-size: 28px;
			color: $color--gray-dark;

			@include max-screen(bp("mobile")) {
				font-size: 18px;
			}
		}

		&-content{
			
		}
	}

	h3{
		margin-top: 2.7rem;
		margin-bottom: 1rem;
		font-size: 18px;
		color: $color--orange;

		@include max-screen(bp("mobile")) {
			font-size: 13px;
		}
	}
}

.section-find-in-out{
	padding: 60px 0;

	@include max-screen(bp("mobile")) {
		padding: 25px 0;
	}

	.section-title{
		font-size: 20px;

		@include max-screen(bp("mobile")) {
			font-size: 16px;
		}
	}
}