/* Reset and Presets */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-size: $font--default-size;
  font-family: $font--default;
  font-weight: 300;
  background-color: $color--background-body;
  color: $color--text;

  @include max-screen(bp("mobile")) {
    font-size: $font--mobile-size;
  }
}

a {
  color: inherit;
  text-decoration: none;
  @include transition(.3s);

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

button, input, select, textarea, a{
  &:focus,
  &:active,
  &:hover {
    outline: 0;
  }
}

ol,
ul {
  list-style: none;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

button, input, textarea, select, iframe{
  outline: 0;
}