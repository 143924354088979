/* Forms / Inputs */
.form-group{
	margin-bottom: 1.6rem;

	&.form-group-search{
		position: relative;
		// margin-bottom: 0;

		.form-control{
			@extend .default-box-shadow;
			border: 0;
			border-radius: 4px;
			height: 60px;
			padding-left: 25px;
			padding-right: 60px;
			font-size: 18px;

			@include max-screen(bp("mobile")) {
				font-size: 14px;
			}
		}

		.search-submit{
			display: block;
			position: absolute;
			width: 60px;
			height: 60px;
			top: 0;
			right: 0;
			border: 0;
			background: {
				image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.997' height='25.002' viewBox='0 0 24.997 25.002'%3E%3Cpath id='Icon_awesome-search' data-name='Icon awesome-search' d='M24.657,21.616l-4.868-4.868a1.171,1.171,0,0,0-.83-.342h-.8a10.151,10.151,0,1,0-1.758,1.758v.8a1.171,1.171,0,0,0,.342.83l4.868,4.868a1.167,1.167,0,0,0,1.655,0l1.382-1.382a1.177,1.177,0,0,0,0-1.66Zm-14.5-5.21a6.25,6.25,0,1,1,6.25-6.25A6.246,6.246,0,0,1,10.156,16.406Z' fill='#{$color--orange}'/%3E%3C/svg%3E%0A");
				repeat: no-repeat;
				position: center;
				color: transparent;
			}
			z-index: 3;
		}
	}
}

.form-control{
	font-weight: 300;
	border-radius: 2px;
	color: $color--gray-medium;
	border-color: $color--gray-border-input;

	@include max-screen(bp("phablet")) {
		padding: .4rem 1rem;
		height: calc(1.4em + 1rem + 2px);
		font-size: .8rem;
	}

	&.error{
		border-color: $color--error;
		color: $color--error;
		
		@include input-placeholder {
			color: $color--error;
		}
	}

  	@include input-placeholder {
    	color: currentColor;
    	opacity: 1;
    	line-height: normal !important;
  	}
}

.custom-checkbox{
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin-right: 5px;

	// + label{
	// 	display: inline-block;
	// 	vertical-align: middle;
	// }
	
	&__mask{
		@include transition(.2s);
		display: inline-block;
		width: 18px;
		height: 17px;
		border-radius: 2px;
		border: 1px solid $color--gray-border-input;
		background:{
			color: $color--white;
			image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' class='svg-inline--fa fa-check fa-w-16 fa-3x'%3E%3Cpath fill='#ffffff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' class=''%3E%3C/path%3E%3C/svg%3E");
			repeat: no-repeat;
			size: 12px;
			position: center;
		}

		input:checked + &{
			background-color: $color--orange;
			border-color: $color--orange;
		}
	}

	&__input{
		position: absolute;
		visibility: hidden;
		opacity: 0;
		z-index: -1;
	}
}

textarea{
	resize: none;
}

.video-upload{
	$color--background: #F5F5F5;
	$color--text: #BFB7B7;
	$color--icon: #E4E4E4;

	@include transition(.4s);
	display: block;
	position: relative;
	padding: 60px 20px;
	background-color: $color--background;
	color: $color--text;
	text-align: center;
	cursor: pointer;

	&:hover{
		background-color: darken($color--background, 2%);
	}

	&__icon{
		display: inline-block;
		width: 100px;
		color: $color--icon;

		svg{
			width: 100%;
		}
	}	

	&__caption{
		color: $color--text;
		font-weight: 500;
	}

	&__input{
		position: absolute;
		visibility: hidden;
		opacity: 0;
		z-index: -3;
	}
}