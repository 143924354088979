.carousel-ad{
	@include max-screen(bp("mobile")) {
		overflow: visible;
		padding-right: 15vw;
	}

	.swiper-slide{
		@include max-screen(bp("mobile")) {
			@include transition(.4s);
		}

		&:not(.swiper-slide-active){
			@include max-screen(bp("mobile")) {
				opacity: .52;
			}
		}
	}

	&__button-prev,
	&__button-next{
		@extend .default-box-shadow;
		@include transition(.4s);
		width: 36px;
		height: 36px;
		background-color: $color--white;
		border-radius: 100%;
		top: 55%;
		margin-top: -18px;
		background-size: 7px;

		&.swiper-button-disabled{
			opacity: 0;
		}

		@include max-screen(bp("mobile")) {
			display: none;
		}
	}

	&__button-prev{
		left: 0;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.333' height='11.078' viewBox='0 0 6.333 11.078'%3E%3Cpath d='M15.675,11.731,11.483,7.543A.792.792,0,0,1,12.6,6.425l4.749,4.746a.79.79,0,0,1,.023,1.092l-4.769,4.779a.792.792,0,0,1-1.121-1.118Z' transform='translate(17.584 17.272) rotate(180)' fill='%236d6b6b'/%3E%3C/svg%3E%0A");
	}

	&__button-next{
		right: 0;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.333' height='11.078' viewBox='0 0 6.333 11.078'%3E%3Cpath d='M15.675,11.731,11.483,7.543A.792.792,0,0,1,12.6,6.425l4.749,4.746a.79.79,0,0,1,.023,1.092l-4.769,4.779a.792.792,0,0,1-1.121-1.118Z' transform='translate(-11.251 -6.194)' fill='%236d6b6b'/%3E%3C/svg%3E%0A");
	}
}