/* Footer */
.footer{
	min-height: 130px;
	border-top: 1px solid rgba($color--gray-border-footer, .14);
	padding: 20px 0;

	@include max-screen(bp("mobile")) {
		padding: 20px 0 40px;
	}

	// p{
	// 	font-size: 12px;
	// }
}

.text-copyright{
	p{
		font-size: 12px;
	}
}

