/* Page */
.container{
	@include max-screen(bp("mobile")) {
		padding-left: 30px;
		padding-right: 30px;
	}
}

.section-term-infos{
	padding: 60px 0 20px;

	@include max-screen(bp("mobile")) {
		padding: 20px 0 15px;
	}
}

.section-most-wanted{
	padding: 70px 0;

	@include max-screen(bp("mobile")) {
		padding: 60px 0 40px;
	}

	.section{
		&-title{
			font-size: 20px;
			color: $color--orange;

			@include max-screen(bp("mobile")) {
				font-size: 13px;
			}
		}

		&-subtitle{
			font-size: 34px;

			@include max-screen(bp("mobile")) {
				font-size: 18px;
			}
		}
	}
}