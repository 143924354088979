.account-form{
	@extend .default-box-shadow;
	padding: 25px 65px 40px;
	border-radius: 4px;
	// background-color: $color--gray-form;
	background-color: $color--white;

	@include max-screen(bp("mobile")) {
		padding: 25px 40px 30px;
	}

	&__title{
		font-size: 18px;
		color: $color--orange;
		text-transform: uppercase;
	}

	.form-group{
		margin-bottom: 15px;
	}

	.form-control{
		// border: 0;
	}

	label{
		color: $color--gray-medium;
	}

	p{
		font-size: 16px;
	}

	a{
		color: $color--orange;
	}
}