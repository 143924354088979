.grid-find{
	width: 100%;
	height: 416px;
	display: flex;
	flex-direction: column;
    flex-wrap: wrap;

    @include max-screen(bp("tablet")) {
    	height: 35vw;
    }

    @include max-screen(bp("phablet")) {
    	height: initial;
    }

	&__item{
		@include flex-justify-content(flex-end);
		position: relative;
	    overflow: hidden;
		border: 2px solid $color--white;
	    flex-direction: column;
	    flex-grow: 1;
	    flex-shrink: 1;

	    @include min-screen(bp("phablet")) {
			&:nth-child(1),
			&:nth-child(4){
				flex-basis: 51%;
				height: 51%;
			}

			&:nth-child(2),
			&:nth-child(5){
				flex-basis: 49%;
				height: 49%;
			}

			&:nth-child(1),
			&:nth-child(2){
				width: 38%;
			}

			&:nth-child(4),
			&:nth-child(5){
				width: 35%;
			}

			&:nth-child(3){
				width: 27%;
				flex-basis: 100%;
				height: 100%;
			}
		}

		@include max-screen(bp("phablet")) {
			flex-basis: 175px;
			height: 175px;
		}

		&:after{
			@include pseudo();
			@include transition(.4s);
			@include gradient($color--black, transparent, to top);
			width: 100%;
			height: 120px;
			bottom: 0;
			left: 0;
			opacity: .83; 
		}

		&:hover{
			&:after{
				opacity: .93;
			}
		}

		&-image{
			position: relative;
			height: 100%;
			margin: 0;

			img{
				@include object-fit();
				@include transition(.4s);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				a:hover &{
					@include scale(1.04);
				}
			}
		}

		&-caption{
			@include transition(.4s);
			position: absolute;
			left: 0;
			bottom: 0;
			color: $color--white;
			padding: 25px 22px;
			z-index: 3;

			@include max-screen(bp("mobile")) {
				padding: 20px;
			}

			a:hover &{
				@include translate(0, -5px);
			}

			&--title{
				font-size: 22px;
				font-weight: 600;
				margin-bottom: .1rem;

				@include max-screen(bp("mobile")) {
					font-size: 18px;
				}
			}

			&--excerpt{
				margin: 0;

				@include max-screen(bp("mobile")) {
					font-size: 12px;
				}
			}
		}
	}
}