table.manage-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: none; 

    th {
        border: none;
        text-align: left;
        padding: 17px 20px;
        color: $color--white;
        vertical-align: top;
        font-weight: 500;
        font-size: 16px;
        position: relative;
        z-index: 9;
        line-height: 22px;
        margin-left: 33px;

        &:first-child {
            width: 50%;
            border-radius: 3px 0 0 3px;
        }

        &:last-child {
            border-radius: 0 3px 3px 0;
        }

        &:first-child i {
            left: 35px;
        }


        i {
            margin-right: 3px;
            font-size: 14px;
        }
    }

    td{
        padding: 35px 20px;
        vertical-align: middle;
        border-bottom: 1px solid #e4e4e4;

        &:first-child {
            padding-left: 0;
            line-height: 25px;
        }

        &.action{
            a{
                display: block;
                line-height: 20px;
                padding: 3px 0;
                position: relative;
                margin-left: 21px;
                color: #333;

                &.delete{
                    &:hover {
                       color: #f02929;
                   }
                }

                i {
                    position: absolute;
                    left: -21px;
                    top: 5px;
                }
            }
        }
    }

    .title-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        img {
            width: 30%;
            border-radius: 3px;
            flex: 1.1;
            margin-right: 26px
        }

        .item-title {
            flex: 2;

            h4 {
                margin-bottom: 4px;
                margin-top: 2px;
                display: block
            }

            span {
                font-weight: 500;
                color: #999;
                display: block;
            }

            a{
                color: #333;
            }

            .table-item-price {
                display: inline-block;
                background: #f0f0f0;
                color: #888;
                border-radius: 2px;
                font-size: 13px;
                margin-top: 9px;
                padding: 2px 10px
            }

            .breadcrumb {
                margin-bottom: 0;
                color: #909090 !important;
                font-size: 13px;

                > li{ 
                    display: inline-block;

                    + li:before {
                        content: "\f101";
                        font-family: FontAwesome;
                        color: #909090;
                        padding: 0 5px;
                    }
                }

                a {
                    color: inherit
                }
            }
        }

        .item-details {
            margin: 0;
            padding: 0;
            font-size: 14px;

            li {
                display: inline-block;
                margin-right: 15px;
                font-weight: 300;

                a {
                    font-weight: 300;
                    color: #6f6f6f
                }

                i {
                    margin-right: 5px;
                    color: $color--orange;
                }
            }
        }
    }
}

mark.color, 
table.manage-table th {
    background-color: $color--orange;
}


.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    color: $color--white;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}



.alert-success, .bg-green, 
.callout.callout-success, 
.label-success, 
.modal-success .modal-body {
    background-color: #00a65a;
    color: $color--white;
}

.label.highlight {
    background-color: #1c90f3;
}