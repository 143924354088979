.post-steps{
	&-progress{
		@include flex-justify-content(space-between);
		padding: 20px;

		@include max-screen(bp("small")) {
			padding: 10px;
		}

		> li{
			padding: 5px;

			> a{
				@include transition(.5s);
				display: block;
				position: relative;
				font-size: 16px;
				font-weight: 500;
				color: $color--gray-thin-step;
				padding-bottom: 5px;

				@include max-screen(bp("small")) {
					font-size: 14px;
				}

				&:after{
					@include pseudo();
					@include transition(.5s);
					bottom: 0;
					height: 2px;
					border-radius: 2px;
					background-color: $color--orange;
					left: 50%;
					right: 50%;
					opacity: 0;
				}

				&.is-active{
					color: $color--orange;

					&:after{
						left: 0;
						right: 0;
						opacity: 1;
					}
				}
			}

		}
	}

	&-wrap{
		display: block;
		overflow: hidden;
		position: relative;
	}
	
	&-form{
		@include transition(.3s);
		background-color: $color--white;

		&:not(.is-active){
			position: absolute;
			visibility: hidden;
			opacity: 0;
		}

		&.is-active{
			position: relative;
			z-index: 9;
		}
	}
}