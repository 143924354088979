.section-breadcrumb{
	background-color: $color--gray-thin;
	padding: 10px 0;

	@include max-screen(bp("phablet")) {
		display: none;
	}

	.breadcrumb{
		@include flex-wrap(wrap);
		margin: 0;

		> li{
			display: block;
			position: relative;
			margin: 8px 8px 8px 0;
			padding-right: 12px;
			font-weight: 300;

			&:not(:last-child){
				&:after{
					@include pseudo();
					@include translate(0, -50%);
					top: 50%;
					right: 0;
					width: 5px;
					height: 5px;
					border-radius: 100%;
					background-color: rgba($color--gray-dark, .4);
				}
			}

			&:last-child{
				font-weight: 600;
			}

			a{
				&:hover{
					color: $color--orange;
				}
			}
		}
	}
}