/* Share Modal */

.share{
	position: relative;

	&-links{
		@include transition(.2s);
		@include translate(-50%, -100%);
		@include flex-align-items(center);
		transition-delay: .2s;
		position: absolute;
		top: 0;
		left: 50%;
		padding: 10px;
		border-radius: 4px;
		background-color: $color--white;
		box-shadow: 0 0 6px rgba($color--black, .1);
		visibility: hidden;
		opacity: 0;

		> a{
			@include transition(.4s);
			padding: 0 10px;
			color: $color--gray-medium;
			font-size: 18px;

			&:hover{
				color: $color--orange;
			}
		}

		.share:hover &{
			z-index: 9;
			opacity: 1;
			visibility: visible;
			@include translate(-50%, calc(-100% - 10px));
		}
	}
}
