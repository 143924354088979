/* Helpers */
.font-weight{
	&-medium{
		font-weight: 500 !important;
	}

	&-semibold{
		font-weight: 600 !important;
	}
}

.text{
	&-orange{
		color: $color--orange;
	}
}

.default-box-shadow{
	box-shadow: 0 3px 6px rgba($color--black, .16);
}

.fluid-mobile{
	@include max-screen(bp("mobile")) {
		margin-left: -30px;
		margin-right: -30px;
	}
}

.rounded-mobile-0{
	@include max-screen(bp("mobile")) {
		border-radius: 0 !important;
	}
}