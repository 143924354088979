.banner-top{
	@include background-cover();
	height: 260px;
	padding-top: 35px;
	padding-bottom: 30px;
	border-radius: 4px;

	@include max-screen(bp("mobile")) {
		border-radius: 0;
		height: 210px;
	}

	&__title{
		font-size: 22px;
		line-height: 1.5;

		@include max-screen(bp("mobile")) {
			font-size: 16px;
		}
	}

	&__subtitle{
		font-size: 18px;
		margin-bottom: 1.5rem;

		@include max-screen(bp("mobile")) {
			font-size: 13px;
		}
	}
}