.archive-search{
	padding-top: 68px;
	padding-bottom: 35px;
	background-color: $color--orange;
	color: $color--white;

	@include max-screen(bp("phablet")) {
		padding-top: 30px;
		padding-bottom: 30px;

		.form-group{
			margin-bottom: 0;
		}
	}

	&__icon{
		padding-top: 10px;
		width: 133px;

		img,
		svg{
			width: 100%;
			height: auto;
		}
	}

	&__title{
		font-size: 20px;
		margin-bottom: 1.5rem;
	}
}

.archive-section{
	padding-top: 60px;

	@include max-screen(bp("phablet")) {
		padding-top: 15px;
	}
}

.filter-icon{
	color: $color--gray-medium;
}