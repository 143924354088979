.dashboard{
	background-color: $color--gray-light;

	&__header{
		min-height: 127px;
		padding-top: 85px;
		background-color: $color--orange;
		color: $color--white;

		@include max-screen(bp("phablet")) {
			min-height: 75px;
			padding-top: 15px;
		}

		@include max-screen(bp("small")) {
			padding-bottom: 10px;
		}

		> .container{
			@include translate(0, 30px);

			@include max-screen(bp("phablet")) {
				@include translate(0, 20px);
				padding-left: 15px;
				padding-right: 15px;
			}
		}

		.dashboard-user{
			@include flex-display();

			&__image{
				display: block;
				position: relative;
				width: 127px;
				height: 127px;
				margin: -25px 13px 0 0;
				border-radius: 100%;
				overflow: hidden;

				@include max-screen(bp("phablet")) {
					width: 95px;
					height: 95px;
					margin: 0 10px 0 0;
				}

				@include max-screen(bp("small")) {
					width: 70px;
					height: 70px;
				}

				img{
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					@include object-fit();
				}
			}

			&__caption{
				width: calc(100% - 140px);

				@include max-screen(bp("phablet")) {
					width: calc(100% - 105px);
					font-size: 10px;
				}

				@include max-screen(bp("small")) {
					width: calc(100% - 80px);
				}

				&-title{
					font-weight: 300;
					font-size: 18px;

					@include max-screen(bp("phablet")) {
						font-size: 14px;
					}

					@include max-screen(bp("small")) {
						font-size: 13px;
					}

					b,
					strong{
						font-weight: 500;
					}
				}
			}
		}

		.dashboard-metrics{
			@include min-screen(bp("phablet")) {
				@include flex-display();
			}

			@include max-screen(bp("phablet")) {
				position: relative;
				text-align: left;

				&:before{
					@include pseudo();
					top: 0;
					bottom: 0;
					left: -20px;
					border-left: 1px solid $color--white
				}
			}

			&__count{
				font-size: 18px;
				font-weight: 500;

				@include max-screen(bp("phablet")) {
					margin-right: 5px;
				}

				@include max-screen(bp("small")) {
					font-size: 14px;
					margin-right: 3px;
				}
			}

			&__title{
				font-weight: 300;

				@include max-screen(bp("phablet")) {

				}

				@include max-screen(bp("small")) {
					font-size: 10px;
				}
			}

			> li{
				display: block;
				text-align: center;

				@include max-screen(bp("phablet")) {
					@include flex-align-items(center);
					text-align: left;
				}				

				&:not(:last-child){
					@include min-screen(bp("phablet")) {
						margin-right: 28px;
					}
				}
			}
		}
	}

	&__content{
		padding-top: 55px;
		padding-bottom: 55px;

		.dashboard-sidebar,
		.dashboard-content{
			@extend .default-box-shadow;
			background-color: $color--white;
			margin-bottom: 30px;
		}

		.dashboard-content{
			padding: 45px 60px;

			@include max-screen(bp("phablet")) {
				padding: 30px;
			}

			h2{
				font-weight: 300;
				margin-bottom: 1.5rem;
			}
		}

		.dashboard-sidebar{
			width: 255px;
			padding: 15px 22px;

			@include max-screen(bp("phablet")) {
				width: 100%;
			}

			.collapse-title{
				font-size: 14px;
				font-weight: 300;
				text-transform: uppercase;

				a{
					display: block;
				}

				.fa{
					float: right;
					color: $color--orange;
				}
			}

			ul.acc-list{
				// padding-left: 15px;

				> li{
					position: relative;
					display: block;
					margin-bottom: 1px;

					a{
						@include transition(.3s);
						@include flex-display();
						position: relative;
						text-transform: uppercase;
						font-size: 12px;
						padding: 5px 40px 5px 15px;
						line-height: 26px;

						.fa{
							line-height: inherit;
							margin-right: 5px;
						}

						&.is-active,
						&:hover{
							background-color: $color--orange;
							color: $color--white;

							.badge{
								background-color: $color--white;
								color: $color--orange;
							}
						}

						.badge{
							@include transition(.3s);
							display: block;
							position: absolute;
							width: 23px;
							height: 16px;
							margin-top: -8px;
							top: 50%;
							right: 15px;
							background-color: $color--orange;
							color: $color--white;
							font-weight: 500;
							font-size: 11px;
							line-height: 16px;
							border-radius: 8px;
							text-align: center;
						}
					}
				}
			}
		}
	}
}