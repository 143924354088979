// Typo
$font--default: 'Poppins', sans-serif;
$font--default-size: 14px;
$font--mobile-size: 12px;

// Colors
$color--white: #ffffff;
$color--black: #000000;
$color--gray: #6D6B6B;
$color--gray-light: #FBFBFB;
$color--gray-thin: #FBF9F9;
$color--gray-medium: #8B8A8A;
$color--gray-dark: #302E2E;
$color--gray-border-footer: #707070;
$color--gray-border-input: #C8C8C8;
$color--gray-line: #B8B7B7;
$color--gray-form: #F4F4F4;
$color--gray-thin-step: #D6D6D6;
$color--orange: #F04E23;

// $color--primary: #ff1b5c;
// $color--secondary: #212121;

$color--success: #4cd137;
$color--error: #e84118;


$color--text: $color--gray-dark;
$color--background-body: $color--white;
$color--default-link: $color--black;
$color--default-link-hover: $color--black;

// Structure
$breakpoints: (
  'minimum': 320px, //*The smallest width that the site is able to shrink to */
  'tiny': 350px,
  'small': 480px,
  'mobile': 576px,
  'phablet': 768px, //*essentially the maximum for iPads in portrait*/
  'tablet': 992px, //*!MAJOR BREAK POINT!*/ /*good place to switch to tablet view*/
  'large': 1024px, //*maximum for iPads in landscape*/
  'page': 1200px, //*!MAJOR BREAK POINT!*//*Point at which the edge of the desktop design meets the edge of the screen*/
  'wide': 1400px,
  'big': 1600px,
  'menu': 1280px
);

// https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css
$unit-spacing: 15px;
$gap: $unit-spacing * 6;

// Misc
$cubic: cubic-bezier(0.4, 0, 0.2, 1);
$box-shadow: 0 2px 7px 0 rgba($color--black, .1);
