.page-account{
	min-height: 100vh;
	background-color: $color--gray-light;

	&__header{
		padding: 50px 0;
		background-color: $color--white;

		@include max-screen(bp("big")) {
			padding: 25px 0;
		}

		@include max-screen(bp("mobile")) {
			padding: 15px 0;
		}

		&-logo{
			img{
				width: 260px;

				@include max-screen(bp("big")) {
					width: 200px;
				}

				@include max-screen(bp("mobile")) {
					width: 135px;
				}
			}
		}
	}

	&__content{
		padding: 60px 0 20px;

		@include max-screen(bp("mobile")) {
			padding: 35px 0 10px;
		}

	}	

	&__footer{
		padding: 30px 0;
	}
}