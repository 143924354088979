/* Header */
.header{
	position: relative;
	background-color: $color--white;
	z-index: 99;

	@include max-screen(bp("tablet")) {
		height: 84px;
		padding-top: 16px;
		// background-color: red;
	}

	&__logo{
		display: inline-block;
		margin: 0;
		padding: 0;

		img{
			max-height: 52px;
		}
	}
}

