.video-player{
	position: relative;
	cursor: pointer;
	width: 100%;
	padding-bottom: 55%;

	video{
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		background-color: $color--black;

		@include max-screen(bp("mobile")) {
			border-radius: 0;
		}
	}

	&__control{
		$size: 90px;
		display: block;
		position: absolute;
		width: $size;
		height: $size;
		margin-top: -$size/2;
		margin-left: -$size/2;
		top: 50%;
		left: 50%;
		z-index: 3;
		background: {
			image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath d='M15,24.75l5.506-4.129L24,18l-9-6.75ZM18,3A15,15,0,1,0,33,18,15.005,15.005,0,0,0,18,3Zm0,27A12,12,0,1,1,30,18,12.016,12.016,0,0,1,18,30Z' transform='translate(-3 -3)' fill='#{$color--white}'/%3E%3C/svg%3E%0A");	
			size: contain;
			repeat: no-repeat; 
		}

		.is-loaded &{
			@include transition(.3s);
		}

		.is-mobile &{
			display: none;
		}


		@include max-screen(bp("phablet")) {
			$size: 70px;
			width: $size;
			height: $size;
			margin-top: -$size/2;
			margin-left: -$size/2;
		}

		.video-player:hover &{
			@include scale(1.07);
		}

		.is-play &{
			visibility: hidden;
			opacity: 0;
		}
	}
}

video{
	outline: 0;
}