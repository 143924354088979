/* Navs */
.nav-header{
	> ul {
		margin: 0;

		@include min-screen(bp("tablet")) {
			@include flex-align-items(center);
			@include flex-wrap(wrap);
		}

		li{
			display: block;

			a{
				position: relative;
				font-weight: 500;

				@include max-screen(bp("tablet")) {
					font-weight: 300;
				}
			}
		}

		> li{
			position: relative;

			&:not(:last-child){
				margin-right: 20px;

				@include max-screen(bp("page")) {
					margin-right: 15px;
				}

				@include max-screen(bp("tablet")) {
					margin-right: 0;
				}
			}

			> a{
				line-height: 86px;

				@include max-screen(bp("tablet")) {
					display: inline-block;
					line-height: normal;
					padding: 6px 0;
				}
			}

			&.is-dropdown{
				> a{
					padding-right: 18px;

					@include max-screen(bp("tablet")) {
						padding-right: 11px;
					}

					&:before{
						@include pseudo();
						width: 12px;
						height: 8px;
						top: 50%;
						right: 0;
						margin-top: -4px;
						background: {
							image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.37' height='6.5' viewBox='0 0 11.37 6.5'%3E%3Cpath d='M1.959,5.686l4.3,4.3a.813.813,0,0,1-1.151,1.147L.237,6.262a.811.811,0,0,1-.024-1.12L5.107.237A.813.813,0,0,1,6.258,1.384Z' transform='translate(0 6.5) rotate(-90)' fill='%23302e2e'/%3E%3C/svg%3E");
							position: center;
							repeat: no-repeat;
						}

						@include max-screen(bp("tablet")) {
							width: 6px;
							height: 3px;
							margin-top: 0;
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='3' viewBox='0 0 6 3'%3E%3Cpath id='Icon_ionic-md-arrow-dropdown' data-name='Icon ionic-md-arrow-dropdown' d='M9,13.5l3,3,3-3Z' transform='translate(-9 -13.5)' fill='%23302e2e'/%3E%3C/svg%3E%0A");
						}
					}
				}

				&:hover{
					> ul{
						@include min-screen(bp("tablet")) {	
							@include translate(0, 0);
							visibility: visible;
							opacity: 1;
						}
					}
				}

				> ul{
					@extend .default-box-shadow;
					padding: 10px 20px 30px 40px;
					background: $color--gray-dark;
					z-index: 3;
					
					@include min-screen(bp("tablet")) {
						@include transition(.4s);
						@include translate(0, -5px);				
						left: 50%;
						width: 240px;
						position: absolute;
						top: 100%;
						margin-left: -120px;
						visibility: hidden;
						opacity: 0;
					}
					
					@include max-screen(bp("tablet")) {
						display: none;
						position: relative;
						padding: 10px 30px 20px;
					}

					&:before{
						@include pseudo();
						top: -7px;
						left: 164px;
						border: {
							top: 0;
							right: 7px solid transparent;
							bottom: 7px solid $color--gray-dark;
							left: 7px solid transparent;
						}

						@include max-screen(bp("tablet")) {
							display: none;
						}
					}

					> li{
						@include min-screen(bp("tablet")) {
							padding: 8px 0;
						}

						&.is-dropdown{
							> a{
								&:before{
									@include pseudo();
									width: 4px;
									height: 7px;
									top: 50%;
									right: 10%;
									margin-top: -3px;
									background: {
										image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3.935' height='6.883' viewBox='0 0 3.935 6.883'%3E%3Cpath id='Icon_ionic-ios-arrow-back' data-name='Icon ionic-ios-arrow-back' d='M14,9.634l-2.6-2.6a.492.492,0,0,1,.7-.695l2.95,2.948a.491.491,0,0,1,.014.678l-2.963,2.969a.492.492,0,1,1-.7-.695Z' transform='translate(-11.251 -6.194)' fill='%23fff'/%3E%3C/svg%3E%0A");
										position: center;
										repeat: no-repeat;
									}

									@include max-screen(bp("tablet")) {
										right: 0;
									}
								}

								@include max-screen(bp("tablet")) {
									padding-right: 10px;
								}
							}
							
							> .megamenu{
								@include min-screen(bp("tablet")) {
									@include transition(.4s);
									@include translate(0, -5px);
									visibility: hidden;
									opacity: 0;
								}

								@include max-screen(bp("tablet")) {
									display: none;
								}
							}

							&:hover{
								> .megamenu{
									@include min-screen(bp("tablet")) {
										@include translate(0, 0);
										transition-delay: .2s;
										visibility: visible;
										opacity: 1;
									}
								}
							}
						}

						> a{
							display: block;
							color: $color--white;

							@include max-screen(bp("tablet")) {
								display: inline-block;
								padding: 8px 0;
							}
						}
					}

				}
			}
		}
	}
}

.megamenu{
	@extend .default-box-shadow;
	background-color: $color--white;

	@include min-screen(bp("tablet")) {
		position: absolute;
		width: 436px;
		top: 0;
		left: 100%;
	}

	&__header{
		padding: 20px 40px 8px;
		border-bottom: 1px solid rgba($color--gray-medium, .3);

		@include max-screen(bp("mobile")) {
			padding: 20px 30px 8px;
		}

		&-title{
			font-size: 18px;
			font-weight: 500;
			line-height: 1;
			margin: 0;
			padding: 0;
		}
	}

	&__body{
		padding: 20px 20px 20px 40px;

		@include max-screen(bp("mobile")) {
			padding: 20px 30px;
		}

		ul{
			@include min-screen(bp("mobile")) {
				@include column-count(2);
				column-gap: 0;
			}


			li{
				display: inline-block;
				width: 100%;
				margin-bottom: 12px;

				a{ 
					font-weight: 300;
				}
			}
		}
	}
}

// Mobile nav
.mobile-nav-wrap{
	@include translate(100%, 0);
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	background-color: $color--white;
	height: 100vh;
	overflow-y: auto;
	z-index: 999;

	.is-loaded &{
		@include transition(.4s);
	}

	&.is-active{
		@include translate(0, 0);
	}
}

.nav-tabs-items{
	margin-bottom: 1.5rem;

	@include max-screen(bp("mobile")) {
		margin-bottom: 1rem;
	}

	.nav-item{
		&:not(:last-child){
			margin-right: 30px;

			@include max-screen(bp("mobile")) {
				margin-right: 24px;
			}
		}

		a{
			font-size: 15px;
			font-weight: 500;
			color: $color--orange;

			@include max-screen(bp("mobile")) {
				font-size: 12px;
			}
		}
	}
}

.tab-content-items{
	font-size: .7rem;
	color: rgba($color--gray-medium, .6);
}