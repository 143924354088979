.main-page--post-videoad{
	.page-content{
		padding-top: 40px;
		padding-bottom: 90px;

		@include max-screen(bp("phablet")) {
			padding-top: 30px;
			padding-bottom: 60px;
		}

		&__boxed{
			@extend .default-box-shadow;
			border-radius: 4px;
			padding-top: 50px;	
			padding-bottom: 80px;

			@include max-screen(bp("tablet")) {
				box-shadow: none;
				padding-top: 0;	
				padding-bottom: 0;
			}
		}
	}
}